<template>
  <span
    @click="$emit('click', $event)"
    class="nice-label"
    :class="{ 'label--small': small, 'label--mini': mini, 'label--clickable': clickable }"
    v-bind="$attrs"
  >
    <span class="nice-label--bg" :class="bgClass" :style="bgStyle"></span>
    <slot>
      {{ label }}
    </slot>
  </span>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
    },
    primary: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
    bgClass: {
      type: String,
      default: "bg-gray-400",
    },
  },

  computed: {
    bgStyle() {
      return {
        "background-color": this.primary ? "#0f55eb" : this.color,
      }
    },
  },
}
</script>

<style>
.nice-label {
  padding: 0 7px;
  height: 18px;
  line-height: 18px;
  font-weight: 400;
  font-size: 13px;
  color: #222222;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
  background: transparent;
  position: relative;
  font-size: 13px;
  letter-spacing: 0.1px;
  padding: 0 7px;
  cursor: inherit;
}

.nice-label.label--clickable {
  cursor: pointer;
}

.nice-label--bg {
  opacity: 0.25;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.nice-label.label--small {
  font-size: 11px;
  padding: 0 4px;
  height: 16px;
  line-height: 16px;
  vertical-align: text-top;
}

.nice-label.label--mini {
  height: 14px;
  line-height: 14px;
  padding: 0 3px;
  font-size: 10px;
}
</style>
