import axios, { CancelTokenSource } from "axios"
import config from "./config"

declare module "axios" {
  interface AxiosInstance {
    handleError: (error: any) => void
    postWithCancel: (url: string, payload: Object) => AxiosPromise<any>
    getWithCancel: (url: string) => AxiosPromise<any>
    isCancel: AxiosStatic["isCancel"]
    CancelToken: AxiosStatic["CancelToken"]
  }
}

const customAxios = axios.create({
  withCredentials: true,
  baseURL: config.BASE_URL,
  headers: {
    Accept: "application/json",
  },
})
customAxios.interceptors.request.use(config => {
  const csfr = (document.querySelector("meta[name=csrf-token]") as any)?.content
  if (csfr) {
    config.headers["X-CSRF-Token"] = csfr
  }
  return config
})
customAxios.interceptors.request.use(config => {
  const query = new URLSearchParams(window.location.search)
  const locale = query.get("locale")
  if (locale) {
    config.headers["Locale"] = locale
  }
  return config
})

customAxios.isCancel = axios.isCancel
customAxios.CancelToken = axios.CancelToken

customAxios.handleError = error => {
  if (error.response && error.response.data) {
    const msg = error.response.data.errors
      ? error.response.data.errors.join(", ")
      : "Etwas ist leider schief gelaufen. Bitte probiere es später erneut."
    App.alert(msg)
  } else if (error.error_messages) {
    App.alert(error.error_messages.join(", "))
  } else {
    console.error(error)
  }
}

let cancelFetch: CancelTokenSource | null = null
customAxios.postWithCancel = (url, payload) => {
  if (cancelFetch) {
    cancelFetch.cancel() // cancel  previous ajax if exists
  }
  cancelFetch = customAxios.CancelToken.source() // creates a new different token for upcomming ajax (overwrite the previous one)
  return customAxios.post(url, payload, { cancelToken: cancelFetch.token })
}

customAxios.getWithCancel = url => {
  if (cancelFetch) {
    cancelFetch.cancel() // cancel  previous ajax if exists
  }
  cancelFetch = customAxios.CancelToken.source() // creates a new different token for upcomming ajax (overwrite the previous one)
  return customAxios.get(url, { cancelToken: cancelFetch.token })
}

export default customAxios
